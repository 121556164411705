import Nav from '../Component/Nav/Nav1';
import LoginContext from '../Context/Logincontext';
import '../styles/Config.css'
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ImExit } from 'react-icons/im'
import { BsFillGearFill } from 'react-icons/bs'
import { urlApiServer } from '../Config/Config';
import fondo1 from '../Assest/Fondo1.jpeg'
import fondo2 from '../Assest/Fondo2.jpeg'
import fondo3 from '../Assest/Fondo3.jpeg'
import fondo4 from '../Assest/Fondo4.jpeg'
import Swal from 'sweetalert2';
import Resizer from 'react-image-file-resizer';
import Loader from "../Component/Loader"
import { useNavigate } from 'react-router-dom';
import LogoComponent from '../Component/LogoComponent';



let splitImg = []

const Config = () => {
    const urlUpdate = urlApiServer + "updateConfig";
    const urlgetConfig = urlApiServer + "getConfig";
    const urlEdit = urlApiServer + "editConfig";
    const urlsubir = urlApiServer + "companyLogo";
    const urlsubir2 = urlApiServer + "companyWallpaper";
    const urlsubir3 = urlApiServer + "signature";
    const urlObservation = urlApiServer + "getFooter";
    const urlUpdateObservation = urlApiServer + "updateFooter";


    const [logo, setLogo] = useState("")
    const [neon, setNeon] = useState("")
    const [innerIconColor, setInnerIconColor] = useState("")
    const [iconColor, setIconColor] = useState("")
    const [mainColor, setMainColor] = useState("")
    const [secondaryColor, setSecondaryColor] = useState("")
    const [fontColor, setFontColor] = useState("")
    const [oldConfig, setOldConfig] = useState("")
    const [idConfig, setIdConfig] = useState("")
    const [neonOld, setNeonOld] = useState("")
    const [innerIconColorOld, setInnerIconColorOld] = useState("")
    const [iconColorOld, setIconColorOld] = useState("")
    const [mainColorOld, setMainColorOld] = useState("")
    const [secondaryColorOld, setSecondaryColorOld] = useState("")
    const [fontColorOld, setFontColorOld] = useState("")
    const [Imagetest, setImageTest] = useState("")
    const [Imagetest2, setImageTest2] = useState("")
    const [Imagetest3, setImageTest3] = useState("")
    const [Imagen, setImagen] = useState("")
    const [Imagen2, setImagen2] = useState("")
    const [Imagen3, setImagen3] = useState("")
    const [ImagetestOld, setImageTestOld] = useState("")
    const [ImagenOld, setImagenOld] = useState([])
    const [Imagetest2Old, setImageTest2Old] = useState("")
    const [Imagen2Old, setImagen2Old] = useState([])
    const [Imagetest3Old, setImageTest3Old] = useState("")
    const [Imagen3Old, setImagen3Old] = useState([])
    const [tittle, setTittle] = useState('')
    const [tittleOld, setTittleOld] = useState('')
    const [ImageSelectionOld, setImageSelectionOld] = useState('')
    const [hasImage, setHasImage] = useState(false);
    const [hasImage2, setHasImage2] = useState(false);
    const [hasImage3, setHasImage3] = useState(false);
    const [heigthImage, setheigthImage] = useState(0)
    const [disabledBottom, setDisabledBottom] = useState(false);
    const [observation, setObservation] = useState("")

    const navigate = useNavigate();
    const { infoUser, setInfoUser } = useContext(LoginContext)

    async function Cerrar() {
        window.location.href = './';
        setInfoUser({
            login: false,
            name: "unknow",
            email: "unknow",
            role: "unknow",
            company: "unknow",
            colorNeonAndButtons: "Red",
            backgroundColorIcons: "Red",
            colorIcons: "white",
            tittleColor: "white",
            letterColor: "white",
            mainColor: [82, 81, 81, 0.691],
            secondaryColor: [82, 81, 81, 0.3],
            logo: "defaultLogo2.png",
            backgroundImg: "default.jpeg",
        })
    }

    const iconsData = [
        { id: 1, imageName: '/Users/adrian/Desktop/Ander/Backupproyects/CotizadorGeniosTec/src/Assest/Fondo1.jpeg', background: `url(${fondo1})` },
        { id: 2, imageName: '/Users/adrian/Desktop/Ander/Backupproyects/CotizadorGeniosTec/src/Assest/Fondo2.jpeg', background: `url(${fondo2})` },
        { id: 3, imageName: '/Users/adrian/Desktop/Ander/Backupproyects/CotizadorGeniosTec/src/Assest/Fondo3.jpeg', background: `url(${fondo3})` },
        { id: 4, imageName: '/Users/adrian/Desktop/Ander/Backupproyects/CotizadorGeniosTec/src/Assest/Fondo4.jpeg', background: `url(${fondo4})` },
    ];

    useEffect(() => {
        (async function () {
            try {

                const InicioHeaders = new Headers();
                InicioHeaders.append("Content-Type", "application/json")

                const requesoption = {
                    method: 'GET',
                    headers: InicioHeaders,
                    redirect: 'follow'
                }
                await fetch(urlgetConfig, requesoption).then(response => response.json()).then(quotesResponse => {
                    setOldConfig(quotesResponse)
                })


                    (async function () {

                        // const InicioHeaders = new Headers();
                        // InicioHeaders.append("Content-Type", "application/json")

                        const registerHeaders = new Headers();
                        registerHeaders.append("Content-Type", "application/json")

                        const data = JSON.stringify({
                            empresa: infoUser.company,
                        })

                        const requesoption = {
                            method: 'POST',
                            headers: registerHeaders,
                            body: data,
                            redirect: 'follow'
                        }
                        await fetch(urlObservation, requesoption).then(response => response.json()).then(item => {
                            if (item.status === 304) {
                                if (item.error) {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: 'Hubo un error al cargar la configuracion del pie de pagina.',
                                        allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
                                        confirmButtonText: 'Aceptar',
                                    })
                                }
                                setObservation("*Al firmar la orden el cliente acepta las especificaciones detalladas en esta. No se aceptarán reclamos si la pieza fabricada cumple con las especificaciones de la orden firmada. \n *Se responde únicamente por medidas y especificaciones anotadas. Una vezaceptada ésta cotizacion no se harán devoluciones. \n *Para realizar cualquier trabajo es necesario abonar el 50% del costo estipulado. \n*Para retirar el trabajo es indispensable presentar este recibo. \n*Pasados 30 dias no se responde por material no retirado.")
                            } else {
                                setObservation(item.footerpdf)
                            }
                        })
                    }())


                    (async function () {

                        // const InicioHeaders = new Headers();
                        // InicioHeaders.append("Content-Type", "application/json")

                        const registerHeaders = new Headers();
                        registerHeaders.append("Content-Type", "application/json")

                        const data = JSON.stringify({
                            empresa: infoUser.company,
                        })

                        const requesoption = {
                            method: 'POST',
                            headers: registerHeaders,
                            body: data,
                            redirect: 'follow'
                        }
                        await fetch(urlObservation, requesoption).then(response => response.json()).then(item => {
                            if (item.footerpdf !== '') {
                                setObservation(item.footerpdf)
                            } else {
                                setObservation("*Al firmar la orden el cliente acepta las especificaciones detalladas en esta. No se aceptarán reclamos si la pieza fabricada cumple con las especificaciones de la orden firmada. \n *Se responde únicamente por medidas y especificaciones anotadas. Una vezaceptada ésta cotizacion no se harán devoluciones. \n *Para realizar cualquier trabajo es necesario abonar el 50% del costo estipulado. \n*Para retirar el trabajo es indispensable presentar este recibo. \n*Pasados 30 dias no se responde por material no retirado.")
                            }
                        })
                    }())


            } catch (error) {
                console.log("error: ", error)
            }
        }())
    }, [])

    useEffect(() => {
        if (oldConfig) {
            const foundConfig = oldConfig.find(obj => obj.empresa === infoUser.company);
            if (foundConfig) {
                const { id, logo, fontcolor, iconcolor, innericoncolor, maincolor, neon, secondarycolor, backgrounddefault, tittle, signature } = foundConfig;
                setLogo(logo);
                setImageTestOld(logo)
                setImagenOld(logo)
                setIdConfig(id)
                setNeonOld(neon)
                setInnerIconColorOld(innericoncolor)
                setIconColorOld(iconcolor)
                setMainColorOld(JSON.parse(maincolor))
                setSecondaryColorOld(JSON.parse(secondarycolor))
                setFontColorOld(fontcolor)
                setImageSelectionOld(backgrounddefault)
                setImageTest2Old(backgrounddefault)
                setImagen2Old(backgrounddefault)
                setImageTest3Old(signature)
                setImagen3Old(signature)
                setTittleOld(tittle)
            }
        }
    }, [oldConfig, infoUser])

    if (logo) {
        splitImg = logo.split("|")
    }


    const handleColorNeonClick = (neon) => {
        setInfoUser({ ...infoUser, colorNeonAndButtons: neon })
        setNeon(neon);
    };

    const handleInnerIconColorClick = (innerIcon) => {
        setInfoUser({ ...infoUser, backgroundColorIcons: innerIcon })
        setInnerIconColor(innerIcon);
    };

    const handleIconColorClick = (icon) => {
        setInfoUser({ ...infoUser, colorIcons: icon })
        setIconColor(icon);
    };

    const handleMainColorClick = (main) => {
        setInfoUser({ ...infoUser, mainColor: main })
        setMainColor(main);
    };

    const handleSecondaryColorClick = (secondary) => {
        setInfoUser({ ...infoUser, secondaryColor: secondary })
        setSecondaryColor(secondary);
    };

    const handleFontColorClick = (font) => {
        setInfoUser({ ...infoUser, letterColor: font })
        setFontColor(font);
    };

    const handleTittleColorClick = (tittle) => {
        setInfoUser({ ...infoUser, tittleColor: tittle });
        setTittle(tittle);
    };

    const handleImageSelection = (e) => {
        setDisabledBottom(true)
        const image2 = e.target.files[0]
        try {
            Resizer.imageFileResizer(
                image2,
                2000, // nuevo ancho
                2000, // nuevo alto
                'JPEG', // formato
                60, // calidad
                0, // rotación
                async (compressedFile) => {
                    const compressedImageURL = URL.createObjectURL(compressedFile);
                    const response = await fetch(compressedImageURL);
                    const blob = await response.blob();
                    const date = new Date();
                    const timestamp = date.getTime();
                    const file = new File([blob], timestamp + "_" + image2.name, { type: "image/png" });
                    setImageTest2(file.name)
                    setHasImage2(file !== undefined ? true : false);
                    setImagen2(file)
                    setDisabledBottom(false)
                },
                'blob' // tipo de salida: 'blob' o 'base64'
            );
        } catch (error) {
            console.error('Error al comprimir la imagen:', error);
            setHasImage(false);
        }

    }

    const handleFirma = (e) => {
        setDisabledBottom(true)
        const image3 = e.target.files[0]
        try {
            Resizer.imageFileResizer(
                image3,
                200, // nuevo ancho
                300, // nuevo alto
                'png', // formato
                60, // calidad
                0, // rotación
                async (compressedFile) => {
                    const compressedImageURL = URL.createObjectURL(compressedFile);
                    const response = await fetch(compressedImageURL);
                    const blob = await response.blob();
                    const date = new Date();
                    const timestamp = date.getTime();
                    const file = new File([blob], timestamp + "_" + image3.name, { type: "image/png" });
                    setImageTest3(file.name)
                    setHasImage3(file !== undefined ? true : false);
                    setImagen3(file)
                    setDisabledBottom(false)
                },
                'blob' // tipo de salida: 'blob' o 'base64'
            );
        } catch (error) {
            console.error('Error al comprimir la imagen:', error);
            setHasImage3(false);
        }

    }

    async function handleImagen(e) {
        setDisabledBottom(true)
        const image = e.target.files[0]
        try {
            // console.log("antes image: ", image.name)
            Resizer.imageFileResizer(
                image,
                1000, // nuevo ancho
                1000, // nuevo alto
                'PNG', // formato
                60, // calidad
                0, // rotación
                async (compressedFile) => {
                    // console.log("compressedFile: ", compressedFile)
                    const compressedImageURL = URL.createObjectURL(compressedFile);
                    // console.log("compressedImageURL: ", compressedImageURL)
                    const response = await fetch(compressedImageURL);
                    const blob = await response.blob();
                    const date = new Date();
                    const timestamp = date.getTime();
                    // console.log("Url blob: ", blob)
                    const file = new File([blob], timestamp + "_" + image.name, { type: "image/png" });
                    // console.log("file: ", file)
                    setImageTest(file.name)
                    setHasImage(file !== undefined ? true : false);
                    setImagen(file)
                    setDisabledBottom(false)
                },
                'blob' // tipo de salida: 'blob' o 'base64'
            );
        } catch (error) {
            console.error('Error al comprimir la imagen:', error);
            setHasImage(false);
        }

    }

    const handleObservation = (event) => {
        // console.log("event: ", event.target.value)
        setObservation(event.target.value)
    };

    async function sendfile(file) {

        var formdata = new FormData();
        formdata.append("file", file);
        formdata.append("name", "foto");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        await fetch(urlsubir, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

    }

    async function sendfile2(file) {

        var formdata = new FormData();
        formdata.append("file", file);
        formdata.append("name", "foto");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        await fetch(urlsubir2, requestOptions)
            .then(response => response.text())
            .then(result => console.log("respuesta al subir img fondo: ", result))
            .catch(error => console.log('error', error));

    }

    async function sendfile3(file) {

        var formdata = new FormData();
        formdata.append("file", file);
        formdata.append("name", "foto");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        await fetch(urlsubir3, requestOptions)
            .then(response => response.text())
            .then(result => console.log("respuesta al subir img fondo: ", result))
            .catch(error => console.log('error', error));

    }

    async function updateConfig() {
        if ((neon !== "") && (fontColor !== "")) {
            if (neon === fontColor) {
                return await Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'El color de los botones y de la letra no deben de ser los mismos, cambia los por favor.',
                    allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
                    confirmButtonText: 'Aceptar',
                })
            }
        }

        setDisabledBottom(true)
        handleUpdateFooter()
        const data = JSON.stringify({
            logo: Imagetest ? Imagetest : "defaultLogo2.png",
            empresa: infoUser.company,
            neon: neon ? neon : "Red",
            innerIconColor: innerIconColor ? innerIconColor : "Red",
            iconColor: iconColor ? iconColor : "white",
            mainColor: mainColor ? mainColor : [82, 81, 81, 0.691],
            secondaryColor: secondaryColor ? secondaryColor : [82, 81, 81, 0.3],
            fontColor: fontColor ? fontColor : "white",
            ImageSelection: Imagetest2 ? Imagetest2 : "default.jpeg",
            signature: Imagetest3 ? Imagetest3 : "signaturedefault.png",
            tittle: tittle ? tittle : "white",
            observation: observation

        })
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: 'POST',
            headers: registerHeaders,
            body: data,
            redirect: 'follow'
        }

        fetch(urlUpdate, requesoption).then(async response => response.json())
            .then(async dataResponse => {
                if (dataResponse) {
                    if (Imagetest) {
                        await sendfile(Imagen)
                    }

                    if (Imagetest2) {
                        await sendfile2(Imagen2)

                    }
                    if (Imagetest3) {
                        await sendfile2(Imagen3)

                    }
                    setDisabledBottom(false)
                    setInfoUser({ ...infoUser, backgroundImg: Imagetest2 ? Imagetest2 : "default.jpeg", logo: Imagetest ? Imagetest : "defaultLogo2.png", signature: Imagetest3 ? Imagetest3 : "signaturedefault.png" })

                    window.location.reload();
                }
            }).catch(err => {
                console.log("error: ", err)
                setDisabledBottom(false)
            })
    }

    async function editConfig() {

        const ImagetestToSend = Imagetest ? Imagetest : ImagetestOld;
        const ImagenToSend = Imagen ? Imagen : ImagenOld;
        const Imagen2ToSend = Imagen2 ? Imagen2 : Imagen2Old;
        const Imagen3ToSend = Imagen3 ? Imagen3 : Imagen3Old;
        const neonToSend = neon ? neon : neonOld;
        const innerIconColorToSend = innerIconColor ? innerIconColor : innerIconColorOld;
        const iconColorToSend = iconColor ? iconColor : iconColorOld;
        const mainColorToSend = mainColor ? mainColor : mainColorOld;
        const secondaryColorToSend = secondaryColor ? secondaryColor : secondaryColorOld;
        const fontColorToSend = fontColor ? fontColor : fontColorOld;
        const titleColorToSend = tittle ? tittle : tittleOld;
        const ImagetestSend2 = Imagetest2 ? Imagetest2 : Imagetest2Old;
        const ImagetestSend3 = Imagetest3 ? Imagetest3 : Imagetest3Old;
        if ((neonToSend !== "") && (fontColorToSend !== "")) {

            if (neonToSend === fontColorToSend) {
                return await Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'El color de los botones y de la letra no deben de ser los mismos, cambialos por favor.',
                    allowOutsideClick: false, // Evita hacer clic fuera del cuadro de diálogo
                    confirmButtonText: 'Aceptar',
                })
            }
        }
        setDisabledBottom(true)
        handleUpdateFooter()
        const data = JSON.stringify({
            id: idConfig,
            logo: ImagetestToSend,
            neon: neonToSend,
            innerIconColor: innerIconColorToSend,
            iconColor: iconColorToSend,
            mainColor: mainColorToSend,
            secondaryColor: secondaryColorToSend,
            fontColor: fontColorToSend,
            ImageSelection: ImagetestSend2,
            Signature: ImagetestSend3,
            tittle: titleColorToSend,
            oldLogo: ImagenOld,
            oldBackground: Imagen2Old,
            oldSignature: Imagen3Old,
            observation: observation
        });
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const requesoption = {
            method: 'PUT',
            headers: registerHeaders,
            body: data,
            redirect: 'follow'
        }

        await fetch(urlEdit, requesoption).then(response => response.json())
            .then(async dataResponse => {

                // ImagenToSend.map((file) => {
                if (Imagen !== "") {
                    await sendfile(Imagen)
                }
                // })
                // Imagen2ToSend.map((file) => {
                if (Imagen2 !== "") {
                    await sendfile2(Imagen2)
                }
                if (Imagen3 !== "") {
                    await sendfile3(Imagen3)
                }
                // })
                setDisabledBottom(false)
                setInfoUser({ ...infoUser, backgroundImg: ImagetestSend2, logo: ImagetestToSend, signature: ImagetestSend3 })
                window.location.reload();
            })
    }

    const options = document.querySelectorAll('.neonOptions');
    const options2 = document.querySelectorAll('.circleOptions');
    const options3 = document.querySelectorAll('.iconNavConfig');
    const options4 = document.querySelectorAll('.mainOptions');
    const options5 = document.querySelectorAll('.secundaryOptions');
    const options6 = document.querySelectorAll('.fontOptions');
    const options7 = document.querySelectorAll('.iconBackgroundImage');
    const options8 = document.querySelectorAll('.tittle');


    function toggleActiveOption() {
        options.forEach(option => option.classList.remove('selected'));

        this.classList.add('selected');
    }

    options2.forEach(option2 => option2.addEventListener('click', toggleActiveOption));

    function toggleActiveOption2() {
        options2.forEach(option2 => option2.classList.remove('selected2'));

        this.classList.add('selected2');
    }

    options.forEach(option2 => option2.addEventListener('click', toggleActiveOption2));

    function toggleActiveOption3() {
        options3.forEach(option3 => option3.classList.remove('selected3'));

        this.classList.add('selected3');
    }

    options3.forEach(option3 => option3.addEventListener('click', toggleActiveOption3));

    function toggleActiveOption4() {
        options4.forEach(option4 => option4.classList.remove('selected4'));

        this.classList.add('selected4');
    }

    options4.forEach(option4 => option4.addEventListener('click', toggleActiveOption4));

    function toggleActiveOption5() {
        options5.forEach(option5 => option5.classList.remove('selected5'));

        this.classList.add('selected5');
    }

    options5.forEach(option5 => option5.addEventListener('click', toggleActiveOption5));

    function toggleActiveOption6() {
        options6.forEach(option6 => option6.classList.remove('selected6'));

        this.classList.add('selected6');
    }

    options6.forEach(option6 => option6.addEventListener('click', toggleActiveOption6));

    function toggleActiveOption7() {
        options7.forEach(option7 => option7.classList.remove('selected7'));

        this.classList.add('selected7');
    }

    options7.forEach(option7 => option7.addEventListener('click', toggleActiveOption7));

    function toggleActiveOption8() {
        options8.forEach(option8 => option8.classList.remove('selected8'));

        this.classList.add('selected8');
    }

    options8.forEach(option8 => option8.addEventListener('click', toggleActiveOption8));

    useEffect(() => {
        // console.log("Info User: ", infoUser)
    }, [infoUser])


    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [observation]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            // El usuario ha presionado la tecla 'Enter'
            // console.log('Tecla Enter presionada:', observation);
            setObservation(observation + '\n')
        }
    };

    const handleUpdateFooter = async () => {
        const registerHeaders = new Headers();
        registerHeaders.append("Content-Type", "application/json")

        const data = JSON.stringify({
            empresa: infoUser.company,
            content: observation
        })

        const requesoption = {
            method: 'PUT',
            headers: registerHeaders,
            body: data,
            redirect: 'follow'
        }
        await fetch(urlUpdateObservation, requesoption).then(response => response.json()).then(item => {
            setObservation(item.footerpdf)
        })
    }


    return (
        <>
            {(infoUser.role === 'SuperUsuario') ? (window.location.href = './Crear_Empresa') : (
                <>
                    <div className="containerLogoAdd">
                        <LogoComponent />
                        <div className='tittleAddIntContainer'>
                            <p className='tittleAdd'>Configuración</p>
                        </div>
                        <div className='closeIntContainer'>
                            <NavLink onClick={Cerrar} className='closeButton'>
                                <span className="tooltipProductMenos">Cerrar Sesión       </span>
                                <ImExit className='iconNav' />
                            </NavLink>
                        </div>
                    </div>
                    {
                        (disabledBottom) && (
                            <div className="backgroundLoader">
                                <Loader />
                            </div>


                        )
                    }
                    <div id='overallContainer' className='overallContainer'>
                        <Nav />
                        <div className="containerConfig">
                            <div className="contentConfig">
                                <div id="generalConfig">
                                    <div className='drawerFormLogin'>
                                        <form className='ContainerProfile'>
                                            <p className='tittleForm'>Configuración general</p>
                                            <div className='leftQuotes'>
                                                <p className='textForm'>Logo:</p>
                                                <div className='centerStyle'>
                                                    <div className={`file-upload ${hasImage ? 'has-image' : ''}`} style={{ marginLeft: '8%' }}>
                                                        <label htmlFor="file-input" className="upload-label">
                                                            <i className="fas fa-cloud-upload-alt"></i>
                                                            <span className="upload-text">{hasImage ? 'Logo almacenado' : 'Haz clic para subir un nuevo logo'}</span>
                                                        </label>
                                                        <input id="file-input" onChange={handleImagen} type="file" className="file-input" accept=".jpg, .jpeg, .png" />
                                                        <div className="progress-bar"></div>
                                                    </div>
                                                </div>

                                                <p className='textForm'>Imagen de fondo:</p>
                                                <div className='centerStyle'>
                                                    <div className={`file-upload ${hasImage2 ? 'has-image' : ''}`} style={{ marginLeft: '8%' }}>
                                                        <label htmlFor="file-input" className="upload-label">
                                                            <i className="fas fa-cloud-upload-alt"></i>
                                                            <span className="upload-text">{hasImage2 ? 'fondo almacenado' : 'Haz clic para cargar un fondo'}</span>
                                                        </label>
                                                        <input id="file-input" onChange={handleImageSelection} type="file" className="file-input" accept=".jpg, .jpeg, .png" />
                                                        <div className="progress-bar"></div>
                                                    </div>
                                                </div>

                                                <p className='textForm'>Firma:</p>
                                                <div className='centerStyle'>
                                                    <div className={`file-upload ${hasImage3 ? 'has-image' : ''}`} style={{ marginLeft: '8%' }}>
                                                        <label htmlFor="file-input" className="upload-label">
                                                            <i className="fas fa-cloud-upload-alt"></i>
                                                            <span className="upload-text">{hasImage3 ? 'fondo almacenado' : 'Haz clic para cargar un fondo'}</span>
                                                        </label>
                                                        <input id="file-input" onChange={handleFirma} type="file" className="file-input" accept=".jpg, .jpeg, .png" />
                                                        <div className="progress-bar"></div>
                                                    </div>
                                                </div>

                                                {/* <input type="file" name="file" onChange={handleImagen} className='Upload' accept=".jpg, .jpeg, .png" id='myLogo' /> */}
                                                <p className='textForm'>Color neon y de botones</p>
                                                <div className="colorIcon">
                                                    {['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Indigo', 'Violet', 'white', 'black'].map((color, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                borderRadius: '50%',
                                                                backgroundColor: '#00f1c40f',
                                                                boxShadow: `0 0 10px 0 ${color} inset, 0 0 10px 4px ${color}`,
                                                                backdropFilter: 'blur(20px)',
                                                                margin: '15px',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => handleColorNeonClick(color)}
                                                            className='neonOptions'
                                                        >
                                                            {index + 1}
                                                        </div>
                                                    ))}
                                                </div>
                                                <p className='textForm'>Color interno de los iconos</p>
                                                <div className="colorIcon">
                                                    {['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Indigo', 'Violet', 'white', 'black'].map((color, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                borderRadius: '50%',
                                                                backgroundColor: color,
                                                                boxShadow: `0 0 10px 0 #00f1c40f inset, 0 0 10px 4px #00f1c40f`,
                                                                backdropFilter: 'blur(20px)',
                                                                margin: '15px',
                                                                cursor: 'pointer',
                                                                color: color === 'white' ? 'black' : undefined
                                                            }}
                                                            onClick={() => handleInnerIconColorClick(color)}
                                                            className='circleOptions'
                                                        >
                                                            {index + 1}
                                                        </div>
                                                    ))}
                                                </div>
                                                <p className='textForm'>Color de los iconos</p>
                                                <div className="colorIcon">
                                                    {['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Indigo', 'Violet', 'white', 'black'].map((color, index) => (
                                                        <BsFillGearFill
                                                            key={index}
                                                            style={{ color: color, cursor: 'pointer' }}
                                                            onClick={() => handleIconColorClick(color)}
                                                            className='iconNavConfig'
                                                        />
                                                    ))}
                                                </div>
                                                <p className='textForm'>Color de titulos</p>
                                                <div className="colorIcon">
                                                    {['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Indigo', 'Violet', 'white', 'black'].map((color, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                backgroundColor: color,
                                                                margin: '15px',
                                                                color: color === 'white' ? 'black' : 'inherit',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => handleTittleColorClick(color)}
                                                            className='tittle'
                                                        >
                                                            {index + 1}
                                                        </div>
                                                    ))}
                                                </div>
                                                <p className='textForm'>Color de letra</p>
                                                <div className="colorIcon">
                                                    {['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Indigo', 'Violet', 'white', 'black'].map((color, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                backgroundColor: color,
                                                                margin: '15px',
                                                                color: color === 'white' ? 'black' : 'inherit',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => handleFontColorClick(color)}
                                                            className='fontOptions'
                                                        >
                                                            {index + 1}
                                                        </div>
                                                    ))}
                                                </div>
                                                <p className='textForm'>Color principal</p>
                                                <div className="colorIcon">
                                                    {[
                                                        [82, 81, 81, 0.691], // Red
                                                        [255, 165, 0, 0.691], // Orange
                                                        [255, 255, 0, 0.691], // Yellow
                                                        [0, 128, 0, 0.691], // Green
                                                        [0, 0, 255, 0.691], // Blue
                                                        [75, 0, 130, 0.691], // Indigo
                                                        [238, 130, 238, 0.691], // Violet
                                                        [255, 255, 255, 0.691], // white
                                                        [0, 0, 0, 0.691], // black
                                                    ].map((main, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                backgroundColor: `rgba(${main[0]}, ${main[1]}, ${main[2]}, ${main[3]})`,
                                                                margin: '15px',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => handleMainColorClick(main)}
                                                            className='mainOptions'
                                                        >
                                                            {index + 1}
                                                        </div>
                                                    ))}
                                                </div>
                                                <p className='textForm'>Color secundario</p>
                                                <div className="colorIcon">
                                                    {[
                                                        [82, 81, 81, 0.3], // Red
                                                        [255, 165, 0, 0.3], // Orange
                                                        [255, 255, 0, 0.3], // Yellow
                                                        [0, 128, 0, 0.3], // Green
                                                        [0, 0, 255, 0.3], // Blue
                                                        [75, 0, 130, 0.3], // Indigo
                                                        [238, 130, 238, 0.3], // Violet
                                                        [255, 255, 255, 0.3], // white
                                                        [0, 0, 0, 0.3], // black
                                                    ].map((secondary, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                backgroundColor: `rgba(${secondary[0]}, ${secondary[1]}, ${secondary[2]}, ${secondary[3]})`,
                                                                margin: '15px',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => handleSecondaryColorClick(secondary)}
                                                            className='secundaryOptions'
                                                        >
                                                            {index + 1}
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        </form>
                                        <label htmlFor="quote">Edite aqui su pie de pagina</label>
                                        <div className="inputQuotesContainer">
                                            <textarea
                                                rows="4"
                                                cols="50"
                                                name="observacion"
                                                id="observacion"
                                                onChange={(e) => handleObservation(e)}
                                                className="textareaQuotes textareaQuotesHeight"
                                                placeholder=" "
                                                value={observation}
                                            ></textarea>
                                        </div>

                                    </div>
                                </div>
                                {
                                    (oldConfig) ?
                                        (
                                            oldConfig.some(obj => obj.empresa === infoUser.company) ? (
                                                <button onClick={editConfig} className="button neon_Effect">
                                                    <p>Guardar</p>
                                                </button>
                                            ) : (
                                                <button onClick={updateConfig} className="button neon_Effect">
                                                    <p>Guardar</p>
                                                </button>
                                            )
                                        ) :
                                        ('')
                                }
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Config